<template>
  <section>
    <!-- <pm-Breadcrumb :home="home" :model="items_bread" /> -->
    <div class="col-md-12">
      <h2 class="titulo-links-escolha">Editar Servidor</h2>
    </div>
    <div class="card card-steps">
      <Step2 :items="items" />
    </div>
    <br />
    <ConfirmarEnvioDeDados
      @prevPage="prevPage"
      @enviarDados="enviarDados"
      :dadosPessoa="dadosPessoa"
    />
    <pm-Toast />
  </section>
</template>

<script>
import Step2 from "@/components/steps2.vue";
import ConfirmarEnvioDeDados from "@/components/servidores/ConfirmarEnvioDeDados.vue";

import vueMask from "vue-jquery-mask";
import store_token_info from "@/store/store_token_info.js";
import { Servidores } from "@/class/servidores";

export default {
  props: {
    basic: {},
    pg: {},
  },
  components: {
    Step2,
    ConfirmarEnvioDeDados,
  },
  name: "confirmarEnvio",
  data() {
    return {
      home: { icon: "pi pi-home", to: "/admin/painel-adm" },
      items_bread: [{ label: "Editar Servidor" }],
      dadosPessoa: [],
      items: [
        {
          number: "1",
          label: "Informações básicas",
          to: "aluno-basic-info-cadastrar",
          active: 0,
        },
        {
          number: "2",
          label: "Endereço e contato",
          to: "2",
          active: 0,
        },
        {
          number: "3",
          label: "Confirmar",
          to: "3",
          active: 1,
        },
        /*{
            number:'1',
            label: 'Informações básicas',
            to: "aluno-basic-info-cadastrar",
            active: 0,
        },
        {
            number:'2',
            label: 'Dados de acesso ao sistema',
            to: "2",
            active: 0,
        },
        {
            number:'3',
            label: 'Endereço e contato',
            to: "3",
            active: 0,
        },
        {
            number:'4',
            label: 'Confirmar',
            to: "4",
            active: 1,
        },*/
      ],
    };
  },
  methods: {
    prevPage(info) {
      this.$router.push({
        name: "endereco-contato-servidor-editar",
        params: { basic: JSON.stringify(info), pg: this.pg },
      });
    },
    async enviarDados(info) {
      const telefonesDaPessoa = [];
      for (const el of info.info.telefone) {
        telefonesDaPessoa.push({
          nome: el.nome,
          identificador: el.identificador,
        });
      }
      const servidor = {
        id: info.info.id,
        nome: info.info.nome,
        sobrenome: info.info.sobrenome,
        nome_mae: info.info.nomeMae,
        nome_pai: info.info.nomePai,
        certidao_nascimento: info.info.certidaoNascimento,
        certidao_casamento: info.info.sexo,
        email: info.info.email,
        rg: info.info.rg,
        cpf: info.info.cpf,
        nascimento: info.info.dataNascimento,
        sexo: info.info.sexo,
        deficiencia: info.info.possuiDeficiencia,
        qual_deficiencia: info.info.deficiencia,
        observacao: info.info.observacao,
        logradouro: info.info.logradouro,
        numero: info.info.numero,
        complemento: info.info.complemento,
        bairro: info.info.bairro,
        cidade: info.info.cidade,
        estado: info.info.estado,
        cep: info.info.cep,
        rg_data_missao: info.info.rgDataEmissao,
        sexo: info.info.sexo,
        nee: info.info.nee,
        password: info.info.senha,
        user_id: store_token_info.usuario_logado,
        telefone: telefonesDaPessoa,
        matricula: info.info.matricula,
        //funcao: info.info.funcao.id,
        escolaridade: info.info.escolaridade,
      };
      try {
        const data = await Servidores.alterar(servidor);

        this.$vaToast.init({
          message: "Editado com sucesso!",
          iconClass: "fa-star-o",
          position: "top-right",
          duration: 2500,
          color: "success",
          fullWidth: false,
        });
        this.$router.push({ name: "servidores", params: { pg: this.pg } });
      } catch (e) {
        this.erro = 1;
        if (e.response.data.validacao) {
          let a = [];
          a = e.response.data.validacao;

          Object.entries(a).forEach(([key, value]) => {
            setTimeout(() => {
              this.$vaToast.init({
                message: value[0],
                iconClass: "fa-star-o",
                position: "top-right",
                duration: 3500,
                fullWidth: false,
                color: "danger",
              });
            }, 500);
          });
        } else {
          let a = [];
          a = e.response.data.erro;

          setTimeout(() => {
            this.$vaToast.init({
              message: a,
              iconClass: "fa-star-o",
              position: "top-right",
              duration: 3500,
              fullWidth: false,
              color: "danger",
            });
          }, 500);
        }
      }
    },
  },
  beforeMount() {
    if (this.basic != undefined) {
      const nv = JSON.parse(this.basic);
      this.dadosPessoa = nv.info;
    }
  },
};
</script>

<style>
.row.row-inside {
  max-width: none;
}

.form-control:focus {
  background-color: #f6f7f6;
  border-color: #673ab7;
  box-shadow: none;
}
.form-control {
  background-color: #f6f7f6;
  border: 1px solid #f6f6f6;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.va-input-wrapper,
fieldset {
  margin-bottom: 0.5rem;
}
</style>
